import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {HygieneService} from '../merchant-hygiene/merchant-hygiene.service';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable, map, startWith} from 'rxjs';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import Swal from 'sweetalert2';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AccessControlDirective } from '../shared/rbac/rbac.directive';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MerchantConfigPopupComponent } from '../merchant-hygiene/merchant-config-popup/merchant-config-popup.component';
@Component({
  selector: 'app-merchant-hygiene-direct',
  templateUrl: './merchant-hygiene-direct.component.html',
  styleUrls: ['../merchant-hygiene/merchant-hygiene.component.scss'],
  standalone:true,
  imports: [
    CommonModule,
    MatIconModule,
    TableComponent,
    MatButtonModule,
    RouterModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    AccessControlDirective,
    MatDialogModule
]
})
export class MerchantHygieneDirectComponent implements OnInit{
  data: any;
  pageSize: number = 10;
  resultsLength: number = 0;
  columns: ColumnData[] = [];
  desktopDisplayedColumns: string[] = [];
  mobileDisplayedColumns: string[] = [];
  showFilters: boolean = false;
  filter_category: string;
  router: any;
  route: any;
  search: any;
  merchants_data: Object;
  allCity: any = [];
  page: any;
  perPage: number;

  filterOptions: Observable<string[]>;
  formControl = new FormControl('');

  range = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null)
  });

  constructor(private hygieneService: HygieneService,public dialog: MatDialog) {
      this.getHygieneMerchants(this.page, this.perPage);
      this.getCitiesList();
  }

  ngOnInit() {
      this.initialise();
  }

  /**
   * initialise all the merchant hygiene table columns and filter option for city list
   */
  initialise(){
      this.columns = [
          {
              columnDef: 'id',
              header: 'Id',
              cell: 'id',
              sticky: true
          },
          {
              columnDef: 'name',
              header: 'Name',
              cell: 'name'
          },
          {
              columnDef: 'location',
              header: 'Location',
              cell: 'location'
          },
          {
              columnDef: 'pincode',
              header: 'Pincode',
              cell: 'pincode'
          },
          {
              columnDef: 'city',
              header: 'City',
              cell: 'city'
          },
          {
              columnDef: 'created_at',
              header: 'Created at',
              cell: 'created_at',
              isDateMedium : true
          },
          {
              columnDef: 'contact_number',
              header: 'Outlet number',
              cell: 'contact_number'
          },
          {
              columnDef: 'status',
              header: 'Status',
              cell: 'available_on_microsite',
              isToggle: true
          },
          {
              columnDef: 'google_place_id',
              header: 'Google Place ID',
              cell: 'google_place_id',
              isSpecialHTML: true
          },
          {
              columnDef: 'manual_popularity',
              header: 'Popularity',
              cell: 'manual_popularity',
              isSpecialHTML: true
          },
          {
            columnDef: 'restaurant',
            header: 'Restaurant profile',
            cell: 'restaurant',
            isSpecialHTML: true
          },
          {
              columnDef: 'active_menu_items',
              header: 'Menu items',
              cell: 'active_menu_items'
          },
          {
              columnDef: 'item_images',
              header: 'Item images',
              cell: 'item_images'
          },
          {
              columnDef: 'background_image_present',
              header: 'Background image',
              cell: 'background_image_present',
              isBool:true
          },
          {
              columnDef: 'live_offers',
              header: 'Live offers',
              cell: 'live_offers'
          },
          {
              columnDef: 'timings',
              header: 'Timings',
              cell: 'timings',
              pipe:'json'
          }
      ];
      this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
      this.mobileDisplayedColumns = ['id', 'name'];

      this.filterOptions = this.formControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._FILTER(value || ''))
      );
  }

  /**
   * Get list of all the merchants active on consumer platform
   * @param page page index in the table
   * @param perPage number of merchants to show per page
   */
  getHygieneMerchants(page: number, perPage: number) {
      this.hygieneService.getHygieneMerchantsDirect(page).subscribe((hygieneMerchants: any) => {
          this.data = hygieneMerchants.data;
          this.resultsLength = hygieneMerchants.total_merchants;
      });
  }

  /**
   * Get list of all the cities
   */
  getCitiesList() {
      this.hygieneService.getCities().subscribe((all_cities) => {
          this.allCity = all_cities;
      });
  }

  /**
   * on selecting city
   * @param event object containing data about selected city option
   */
  onCitySelected(event:any){
     this.search=this.formControl.value;
  }

  /**
   * clear the value of the filter whenever the filter is changed
   */
  clearText() {
      this.search = null;
  }
  
  /**
   * Filter city list as per the input of the user
   * @param value serach term entered by user
   * @returns returns array of cities that match the searched text
   */
  private _FILTER(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.allCity.filter((option) => option.toLowerCase().includes(filterValue));
  }

  
  /**
   * Change page to view more merchants in the list
   * @param event containing details about page index and size
   */
  changePage(event: any) {
      if (this.filter_category && this.search) {
        this.applyFilters(event.pageIndex);
      } else {
        this.page = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getHygieneMerchants(event.pageIndex, event.pageSize);
      }
  }

  /**
   * Show the merchant list filtered by the options selected by the users
   * @param page page number
   */
  applyFilters(page: number) {
      let obj = {};
      switch(this.filter_category){
        case 'created_at':
            let createdStartDate=new Date(this.range.get('start').value);
            let startDate=`${createdStartDate.getDate()}/${createdStartDate.getMonth() + 1}/${createdStartDate.getFullYear()}`;
            let createdEndDate=new Date(this.range.get('end').value);
            let endDate=`${createdEndDate.getDate()}/${createdEndDate.getMonth() + 1}/${createdEndDate.getFullYear()}`;
            obj['created_at_from'] = startDate;
            obj['created_at_to'] = endDate;
        break;
        case 'status':
            obj['available_on_microsite'] = this.search;
        break;
        default:
           obj[this.filter_category] = this.search;
      }
      let tempData=this.data;
      this.data=null;
      this.hygieneService.filterMerchants(JSON.stringify(obj), page,'microsite').subscribe((data: any) => {
          this.data = data.data;
          this.resultsLength = data.total_merchants;
      },(err)=>{
          this.data=tempData;
          Swal.fire({title: 'Error', text: 'Error in applyingthe filter', icon: 'error', timer: 4000, timerProgressBar: true});
      });
  }


  /**
   * toggle the status of the merchant so it can go live on consumer app
   * @param event 
   */
  actionClicked(event:any) {
      if (event.action) {
          this.hygieneService.changeStatus({id: event.row.id,available_on_ondc: event.row.available_on_ondc,available_on_microsite: event.checked,status:event.row.status}).subscribe(() => {
              Swal.fire({title: 'Status Updated', text: 'Status updated successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
          },(error:any)=>{
            Swal.fire({title: 'Failure', text: error?.error?.message , icon: 'error', timer: 4000, timerProgressBar: true,allowOutsideClick: false}).then((result) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                    this.getHygieneMerchants(this.page, this.perPage);
                }
            }); 
          });
      }
  }

  /**
   * download the list of all the merchants active on consumer with their data
   */
  downloadSheet() {
      this.hygieneService.getSheet('microsite').subscribe((response: any) => {
          Swal.fire({title: 'Download Initiated', text: 'Report will be sent to you shortly via slack channel.', icon: 'success', timer: 4000, timerProgressBar: true});
      }),(error: any) => Swal.fire({title: 'Download Error', text: 'An error has occured', icon: 'error', timer: 4000, timerProgressBar: true});
  }
  /**
     * When user click on link google id and update popularity configureMissingDetails method trigger and open the popup
     * @param row row data
     * @param title popup title
     */
    configureMissingDetails(row:any,title:string){
        const dialogRef = this.dialog.open(MerchantConfigPopupComponent, {
            width: window.innerWidth < 768 ? '100vw' : '40vw',
            data: {merchant_info:row,popup_title:title},
            panelClass: ['thrive-dialog', 'sm-full_width_dailog']
        });
        dialogRef.afterClosed().subscribe((result) => {
            if(result){
                setTimeout(() => {
                    this.getHygieneMerchants(this.page, this.perPage);
                }, 2000); 
            }
        })
    }
}
