import { Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OverviewComponent } from './overview/overview.component';
import { ModerationToolComponent } from './moderation-tool/moderation-tool.component';
import { ModerationToolEditComponent } from './moderation-tool/moderation-tool-edit/moderation-tool-edit.component';
import { AuthGuard } from './auth.guard';
import { AppVersionComponent } from './app-version/app-version.component';
import { BannersComponent } from './banners/banners.component';
import { BannerEditComponent } from './banners/banner-edit/banner-edit.component';
import { CategoryComponent } from './category/category.component';
import { CategoryEditComponent } from './category/category-edit/category-edit.component';
import { DeliveryRadiusComponent } from './delivery-radius/delivery-radius.component';
import { RadiusEditComponent } from './delivery-radius/radius-edit/radius-edit.component';
import { MerchantComponent } from './merchant/merchant.component';
import { MerchantEditComponent } from './merchant/merchant-edit/merchant-edit.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { AdminFormEditComponent } from './admin-user/admin-form-edit/admin-form-edit.component';
import { SourceManagementComponent } from './source-management/source-management.component';
import { SourceManagementEditComponent } from './source-management/source-management-edit/source-management-edit.component';
import { MerchantUsersComponent } from './merchant-users/merchant-users.component';
import { MerchantUsersCreateComponent } from './merchant-users/merchant-users-create/merchant-users-create.component';
import { BulkUploadComponent } from './merchant/bulk-upload/bulk-upload.component';
import { BrandCollaborationComponent } from './brand-collaboration/brand-collaboration.component';
import { BrandCollaborationEditComponent } from './brand-collaboration/brand-collaboration-edit/brand-collaboration-edit.component';
import { MerchantHygieneComponent } from './merchant-hygiene/merchant-hygiene.component';
import { ThrivePricingComponent } from './thrive-pricing/thrive-pricing.component';
import { ThrivePricingCreateComponent } from './thrive-pricing/thrive-pricing-create/thrive-pricing-create.component';
import { ThriveTagsComponent } from './thrive-tags/thrive-tags.component';
import { ThriveTagEditComponent } from './thrive-tags/thrive-tags-edit/thrive-tags-edit.component';
import { CustomMenuComponent } from './custom-menu/custom-menu.component';
import { CustomMenuEditComponent } from './custom-menu/custom-menu-edit/custom-menu-edit.component';
import { accessGuard } from './shared/rbac/rbac.guard';
import { CommentComponent } from './moderation-tool/comment/comment.component';
import { MerchantHygieneDirectComponent } from './merchant-hygiene-direct/merchant-hygiene-direct.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { RestaurantsEditComponent} from './restaurants/restaurants-edit/restaurants-edit.component';
import { MenuCardComponent } from './restaurants/restaurants-edit/menu-card/menu-card.component';
import { CuisineComponent } from './cuisine/cuisine.component';
import { CuisineEditComponent } from './cuisine/cuisine-edit/cuisine-edit.component';
import { TagsComponent } from './tags/tags.component';
import { CreateEditComponent } from './tags/create-edit/create-edit.component';
import { BulkOfferUploadComponent} from './bulk-offer-upload/bulk-offer-upload.component';
import { MerchantHygieneOndcComponent } from './merchant-hygiene-ondc/merchant-hygiene-ondc.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { OperationalHoursComponent } from './reporting/operational-hours/operational-hours.component';
import { CatalogueRejectionComponent } from './reporting/catalogue-rejection/catalogue-rejection.component';
import {  UploadCertificateComponent } from './merchant/upload-certificate/upload-certificate.component';
export const routes: Route[] = [
   {
       path: '',
       redirectTo: 'login',
       pathMatch: 'full'
   },
   {
       path: 'login',
       component: LoginComponent
   },
   {
       path: '',
       component: OverviewComponent,
       canActivate: [AuthGuard],
       children: [
           {
               path: 'delivery-radius',
               canActivate: [accessGuard],
               data: ['delivery_radius'],
               component: DeliveryRadiusComponent
           },
           {
               path: 'delivery-radius/:id',
               canActivate: [accessGuard],
               data: ['delivery_radius'],
               component: RadiusEditComponent
           },
           {
               path: 'merchant',
               component: MerchantComponent
           },
           {
               path: 'merchant/bulk-upload',
               component: BulkUploadComponent
           },
           {
            path: 'merchant/upload-cretificate',
            component: UploadCertificateComponent
           },
           {
               path: 'merchant/:id',
               component: MerchantEditComponent
           },
           {
               path:'bulk-offer-upload',
               component:BulkOfferUploadComponent
           },
           {
               path: 'custom-menu',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: CustomMenuComponent
           },
           {
               path: 'custom-menu/new',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: CustomMenuEditComponent
           },
           {
               path: 'custom-menu/:id/:resto_id',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: CustomMenuEditComponent
           },
           {
               path: 'restaurants',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: RestaurantsComponent


           },
           {
               path: 'restaurants/new',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: RestaurantsEditComponent


           },
           {
               path: 'restaurants/:id',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: RestaurantsEditComponent

           },
           {
               path: 'restaurants/:id/:restaurant_name',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: MenuCardComponent


           },
           {
               path: 'restaurants/:id/:restaurant_name/:menu_category_id',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: MenuCardComponent


           },
           {
               path: 'app-banner',
               canActivate: [accessGuard],
               data: ['app_banners'],
               component: BannersComponent
           },
           {
               path: 'app-banner/:id',
               canActivate: [accessGuard],
               data: ['app_banners'],
               component: BannerEditComponent
           },
           {
               path: 'app-category',
               canActivate: [accessGuard],
               data: ['app_category'],
               component: CategoryComponent
           },
           {
               path: 'app-category/:id',
               canActivate: [accessGuard],
               data: ['app_category'],
               component: CategoryEditComponent
           },
           {
               path: 'app-version',
               canActivate: [accessGuard],
               data: ['app_version'],
               component: AppVersionComponent
           },
           {
               path: 'admin-user',
               canActivate: [accessGuard],
               data: ['admin_users'],
               component: AdminUserComponent
           },
           {
               path: 'admin-user/:id',
               canActivate: [accessGuard],
               data: ['admin_users'],
               component: AdminFormEditComponent
           },
           {
               path: 'moderation-tool',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: ModerationToolComponent
           },
           {
               path: 'moderation-tool/comment',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: CommentComponent
           },
           {
               path: 'moderation-tool/:id',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: ModerationToolEditComponent
           },
           {
               path: 'moderation-tool/:id/:module',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: ModerationToolEditComponent
           },
           {
               path: 'moderation-tool/:id/:module/:moduleId',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: ModerationToolEditComponent
           },
           {
               path: 'user-profile',
               canActivate: [accessGuard],
               data: ['moderation_tool'],
               component: UserProfileComponent
           },
           {
               path: 'source-management',
               canActivate: [accessGuard],
               data: ['source_management'],
               component: SourceManagementComponent
           },
           {
               path: 'source-management/:id',
               canActivate: [accessGuard],
               data: ['source_management'],
               component: SourceManagementEditComponent
           },
           {
               path: 'merchant-users',
               canActivate: [accessGuard],
               data: ['merchant_users'],
               component: MerchantUsersComponent
           },
           {
               path: 'merchant-users/:id',
               canActivate: [accessGuard],
               data: ['merchant_users'],
               component: MerchantUsersCreateComponent
           },
           {
               path: 'brand-collaboration',
               canActivate: [accessGuard],
               data: ['brand_collaboration'],
               component: BrandCollaborationComponent
           },
           {
               path: 'brand-collaboration/:id',
               canActivate: [accessGuard],
               data: ['brand_collaboration'],
               component: BrandCollaborationEditComponent
           },
           {
               path: 'merchant-hygiene/consumer',
               canActivate:[accessGuard],
               data:['merchant_hygiene'],
               component: MerchantHygieneComponent
           },
           {
               path: 'merchant-hygiene/direct',
               canActivate:[accessGuard],
               data:['merchant_hygiene'],
               component: MerchantHygieneDirectComponent
           },
           {
               path: 'merchant-hygiene/ondc',
               canActivate:[accessGuard],
               data:['merchant_hygiene'],
               component: MerchantHygieneOndcComponent
           },
           {
               path: 'thrive-pricing',
               canActivate: [accessGuard],
               data: ['thrive_pricing'],
               component: ThrivePricingComponent
           },
           {
               path: 'thrive-pricing/:id',
               canActivate: [accessGuard],
               data: ['thrive_pricing'],
               component: ThrivePricingCreateComponent
           },
           {
               path: 'thrive-tags',
               canActivate: [accessGuard],
               data: ['thrive_tags'],
               component: ThriveTagsComponent
           },
           {
               path: 'thrive-tags/:id',
               canActivate: [accessGuard],
               data: ['thrive_tags'],
               component: ThriveTagEditComponent
           },
           {
               path:'cuisine',
               component:CuisineComponent
           },
           {
               path:'cuisine/:id',
               component:CuisineEditComponent
           },
           {
               path:'tags',
               component:TagsComponent
           },
           {
               path:'tags/:id',
               component:CreateEditComponent
           },
           {
               path:'reporting/operational-hours',
               component:OperationalHoursComponent
           },
           {
               path:'reporting/catalogue-rejection',
               component:CatalogueRejectionComponent
           }
       ]
   },
   {
       path: '**',
       redirectTo: 'merchant'
   }
];
