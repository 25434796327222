import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelect, MatSelectModule} from '@angular/material/select';
import Swal from 'sweetalert2';
import {ReportingService } from '../reporting.service';
import {Ichain,Imerchant } from '../../merchant-users/merchant-users-create/chainsormerchants';
import {MerchantUsersService } from '../../merchant-users/merchant-users.service';
import {debounceTime, startWith} from 'rxjs';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
@Component({
  selector: 'app-operational-hours',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatRadioModule,
    NgxMatSelectSearchModule,
    MatNativeDateModule,
    MatDatepickerModule],
  providers:[ReportingService],
  templateUrl: './operational-hours.component.html',
  styleUrls: ['./operational-hours.component.scss']
})
export class OperationalHoursComponent implements OnInit{
  reporting: FormGroup;
  getMerchantList: Array<Imerchant>;
  getChainList: Array<Ichain>;
  searchMerchantControl: FormControl = new FormControl('');
  filterChains: Array<Ichain>;
  selectedMerchant:Array<Imerchant>=[];
  maxDate:Date = new Date();
  constructor(private reportingService:ReportingService, 
              private fb: FormBuilder,
              private merchantUserService:MerchantUsersService) {
      this.reporting = this.fb.group({
          role: ['merchant'],
          chain_id: [null],
          id: [null,Validators.required],
          ordering_type:['delivery',Validators.required],
          start_date:['',Validators.required],
          end_date:['',Validators.required]
      });
  }

  ngOnInit(): void {
    this.getMerchants('',()=>{}); 
    this.getChains(()=>{ this.filterChains = this.getChainList;});
    this.searchMerchantControl.valueChanges.pipe(startWith(''),debounceTime(500)).subscribe((value: string) => {
      if(value){
        value = value?.toLowerCase();
        this._filter(value);
      }
    }); 
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    this.reporting.get('start_date').setValue(sevenDaysAgo);
    this.reporting.get('end_date').setValue(currentDate);
  }

  /**
     * To filter the search value from Array of objects
     * @param value To compare and check if its included in array of objects
     * @returns Values that are included in the array
  */
   private _filter(value: string) {
    switch (this.reporting.get('role').value) {
        case 'merchant':
          this.getMerchants(value,()=>{
            let merchant:Imerchant[] =  this.getMerchantList;
            let newList:Imerchant[] = [...this.selectedMerchant];
            merchant.forEach(m=>{
                if(this.selectedMerchant.findIndex(mb=>mb.id === m.id) < 0)
                newList.push(m)
            });
            this.getMerchantList = newList; 
        })
        break;
        case 'chain_owner':
          this.filterChains = [];
          this.filterChains = this.getChainList.filter((chain: any) => chain?.name?.toLowerCase().includes(value));
        break;
    }
    return;
  }

  onChangeRole(event:any){
    switch (event.value) {
      case 'merchant':
        this.reporting.get('chain_id').clearValidators();
        this.reporting.get('chain_d').updateValueAndValidity();
        this.reporting.get('id').setValidators(Validators.required);
        this.reporting.get('id').updateValueAndValidity();
        this.reporting.get('chain_id').setValue(null);
      break;
      case 'chain_owner':
        this.reporting.get('id').clearValidators();
        this.reporting.get('id').updateValueAndValidity();
        this.reporting.get('chain_id').setValidators(Validators.required);
        this.reporting.get('chain_id').updateValueAndValidity();
        this.reporting.get('id').setValue(null);
      break;
    }
  }

  getMerchants(searchText:string,cb:any){
    this.merchantUserService.getMerchantList(searchText).subscribe((response:any)=>{
        this.getMerchantList =  response.merchants;
        cb();
    }) 
  }

  getChains(cb:any){
    this.merchantUserService.getChainList().subscribe((response:any)=>{
        this.getChainList = response.chains;
        cb();
    })   
  }

  onSelect(event:MatSelect){
    this.reporting.get('id').setValue(event.value);
    this.selectedMerchant = [] = event.value;
  }

  // Converts a date to ISO string in UTC
  toUTCString(date: Date): string {
    return new Date(date?.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]; 
  }

  submit(){
    if(this.reporting.valid){
      if(this.reporting.get('role').value === 'merchant'){
        let ids:Array<number> = [] = this.selectedMerchant.map(e => e.id);
        this.reporting.get('id').setValue(ids);
      }
      this.reporting.get('start_date').setValue(this.toUTCString(this.reporting.get('start_date').value));
      this.reporting.get('end_date').setValue(this.toUTCString(this.reporting.get('end_date').value));
      this.reportingService.generateReport(this.reporting.value).subscribe((response:any)=>{
        Swal.fire('Report generated!', 'Report has been generated successfully!', 'success');
        this.selectedMerchant = [];
        this.reporting.get('id').setValue(null);
        this.reporting.get('chain_id').setValue(null);
        this.reporting.get('start_date').setValue(new Date(this.reporting.get('start_date').value));
        this.reporting.get('end_date').setValue(new Date(this.reporting.get('end_date').value));
      },(error:any)=>{
        this.reporting.get('id').setValue(this.selectedMerchant);
        this.reporting.get('start_date').setValue(new Date(this.reporting.get('start_date').value));
        this.reporting.get('end_date').setValue(new Date(this.reporting.get('end_date').value));
        Swal.fire('Failure!', error?.error?.error_message ? error.error.error_message : 'Something went wrong! Please refresh the page or try again later.', 'error');
      }) 
    }
  }
}
