import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MerchantService } from '../merchant.service';
import Swal from 'sweetalert2';
import { MatIconModule } from '@angular/material/icon';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
@Component({
  selector: 'app-upload-certificate',
  standalone: true,
  imports: [CommonModule,
            MatButtonModule,
            MatCardModule,
            MatIconModule,
            RouterLink,
            MatRadioModule,
            MatInputModule,
            MatFormFieldModule,
            ReactiveFormsModule],
  templateUrl: './upload-certificate.component.html',
  styleUrls: ['./upload-certificate.component.scss']
})
export class UploadCertificateComponent {
  selectedFiles: File[] = [];
  uploadCertificate: FormGroup;

  constructor(private fb: FormBuilder,private merchantService:MerchantService) {
    // Initialize the reactive form
    this.uploadCertificate = this.fb.group({
      certificate_type: ['', Validators.required],
      certificates: [null, Validators.required],
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      this.selectedFiles = Array.from(input.files);
      this.uploadCertificate.get('certificates').setValue(this.selectedFiles);
    }
    // Reset the file input value to allow selecting the same file again
    input.value = '';
  }

  removeFile(file: File): void {
    this.selectedFiles = this.selectedFiles.filter(f => f !== file);
    this.uploadCertificate.get('certificates').setValue(this.selectedFiles);
  }

  onSubmit(): void {
    const formData = new FormData();
    this.selectedFiles.forEach(file => formData.append('certificates[]', file));
    formData.append('certificate_type',this.uploadCertificate.value.certificate_type);
    this.merchantService.uploadCertificate(formData).subscribe((response)=>{
      Swal.fire('Uploaded!', 'Certificates uploaded successfully!', 'success');
      this.selectedFiles = [];
      this.uploadCertificate.reset();
    },error=>{
      Swal.fire('Failure', 'Certificates are not uploaded!', 'error');
    });
  }

}
