import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MerchantService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getMerchants(page: number = 0) {
        return this.http.get(this.url.adminUrl + '/merchants?page_no=' + (page + 1));
    }

    getMerchantById(id: number) {
        return this.http.get(this.url.adminUrl + '/v2/merchants/' + id);
    }

    getCities() {
        return this.http.get(this.url.adminUrl + '/admin/dashboard/cities/');
    }

    getPlans() {
        return this.http.get(this.url.adminUrl + '/pg_plans');
    }

    getRestaurantTypes() {
        return this.http.get(this.url.adminUrl + '/v2/merchant_sub_categories');
    }

    getCuisines() {
        return this.http.get(this.url.adminUrl + '/cuisines');
    }

    filterMerchants(filter_category: string, search: string, page: number) {
        return this.http.get(this.url.adminUrl + '/merchants?search_type=' + filter_category + '&search_content=' + search + '&page_no=' + (page + 1));
    }
    
    bulkUpload(formData) {
        return this.http.post(this.url.adminUrl + '/upload_merchant_data', formData);
    }

    getChains() {
        return this.http.get(this.url.adminUrl + "/chains");
    }

    getChainOwnersDetails(chainId:number) {
        return this.http.get(this.url.adminUrl + `/v2/chain-owner-details?chain_id=${chainId}`);
    }
    
    getPosList() {
        return this.http.get(this.url.adminUrl + "/pos_types");
    }

    getEligiblePlatforms(city:string){
        return this.http.get(this.url.adminUrl+"/v2/merchants/merchant_eligible_platforms?city="+city);
    }

    verifyGstNumber(gstNumber:string,merchantId:string){
        return this.http.post(this.url.adminUrl+"/v2/merchants/verify_gst",{gst_number:gstNumber,id:merchantId});
    }

    verifyPanNumber(panNumber:string){
        return this.http.post(this.url.adminUrl+"/v2/merchants/verify_pan",{pan_number:panNumber});
    }

    verifyFSSAINumber(license_no:string){
        return this.http.post(this.url.adminUrl+"/v2/merchants/verify_fssai",{fssai_number:license_no});  
    }

    getMapLocation(paramsAndURL){
        return this.http.get(this.url.adminUrl+paramsAndURL);
    }

    createMerchant(body:any){
        return this.http.post(this.url.adminUrl+'/v2/merchants',body);
    }

    editMerchant(body:any,merchantId:number){
        return this.http.put(this.url.adminUrl+`/v2/merchants/${merchantId}`,body);
    }

    // unique url
    getUniqueSlug(data:any){
        console.log(data);
        let headers =  new HttpHeaders({
            Authorization: data.auth_token,
            "Content-Type": "application/json"
        }); 
        return this.http.get(this.url.adminUrl + "/validate_merchant_slug?slug="+data.slug,{headers});
    }

    getCustomMessage(data:any) {
        return this.http.get(this.url.adminUrl+"/merchant_custom_messages/tags?id="+data.account_details.id);
    }

    getAccountManagerList(){
        let httpParams = new HttpParams();
        httpParams = httpParams.append('support', true);
        return this.http.get(this.url.adminUrl + '/admins',{params: httpParams});
    }

    getJoiningReason(){
        return this.http.get(this.url.adminUrl+'/sign_up_reason');
    }

    getBusinessType(){
        let params = {type: 'banking'};
        return this.http.get(this.url.businessURL+'/v3/sign_up/sign_up_fields',{params})
    }

    getBankDetails(value:string){
        let params = {ifsc_code:  value}
        return this.http.get(this.url.businessURL+'/v2/bank_details',{params})
    }

    verifyBankDetails(accountNumber:number, ifscCode:string) {
        return this.http.post(`${this.url.businessURL}/v4/merchants/verify_bank_details?account_number=${accountNumber}&ifsc_code=${ifscCode}`, {});
    }

    uploadCertificate(body:any){
        console.log(body)
        return this.http.post(this.url.adminUrl+'/bulk_upload/upload_certificates',body);
    }
}
