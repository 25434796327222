import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ReportingService {
    private url = environment;
    constructor(public http: HttpClient) {}
    generateReport(body){
       return this.http.post(this.url.adminUrl + '/v2/generate_operational_hours_report',body);
    }

    uploadCatalogueRejection(body:any){
        return this.http.post(this.url.adminUrl + '/v2/upload_ondc_catalogue_rejections',body)
    }
}

