import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit, QueryList, ViewChildren,ChangeDetectorRef } from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule,MatMenu } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { menuItems } from './menu';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
import Swal from 'sweetalert2';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        MatToolbarModule,
        MatSidenavModule,
        MatMenuModule,
        MatListModule,
        RouterModule,
        AccessControlDirective
    ]
})
export class NavbarComponent implements OnInit {
    @ViewChildren(MatMenu) menus!: QueryList<MatMenu>;
    menuMap: { [key: string]: MatMenu } = {};
    @Input() isExpanded: boolean = false;
    is_touch_device = window.innerWidth < 768;
    menuItems = menuItems;
    selectedHoverMenu: string;
    showTooltip = false;
    loggedInName = '';
    currentLink: any;
    menuTrigger: MatMenu | null = null;
    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {
        this.loggedInName = localStorage.getItem('name');
        this.isExpanded = this.is_touch_device ? true : false;
    }

    ngOnInit(): void {
        this.currentLink = this.router.url;
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.currentLink = event.url;
            }
        });
    }
    ngAfterViewInit() {
        this.menuItems.forEach((item, index) => {
          this.menuMap[item.icon] = this.menus.toArray()[index];
        });
        this.cdr.detectChanges();  // Manually trigger change detection
    }

    getMenuById(icon: string): MatMenu | undefined {
        return this.menuMap[icon];
    }

    getMenuTrigger(item: any): MatMenu | null {
        // Ensure item and icon exist, then return the menu trigger
        if (!this.is_touch_device && item && item.icon) { 
            return this.getMenuById(item.icon);
        }
        return null;
      }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.is_touch_device = window.innerWidth < 768;
        this.isExpanded = this.is_touch_device ? true : false;
    }

    logout() {
        Swal.fire({
            title: 'Are you sure you want to logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#6236FF',
            cancelButtonColor: '#B95000',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                this.router.navigateByUrl('/login');
            }
        });
    }

    toggleMenu() {
        this.isExpanded = !this.isExpanded;
        this.selectedHoverMenu = '';
    }

    navigateTo(name) {
        if ((name == 'Moderation Tool' || name == 'Merchant Hygiene') && this.is_touch_device) {
            let selectedMenu = this.menuItems.filter((menu) => menu.name == name)[0];
            selectedMenu.show_drop_down = !selectedMenu.show_drop_down;
        } else if (name == 'Logout') {
            this.logout();
        } else {
            this.currentLink = this.router.url;
            this.selectedHoverMenu = (name == 'Moderation Tool' || name == 'Merchant Hygiene') ? name : '';
            this.closeExpandedSidebar();
            this.showTooltip = true;
        }
    }

    closeExpandedSidebar() {
        this.menuItems.forEach(menu => menu.show_drop_down = false);
    }

    subMenuSelectedLink(name: string) {
        let state: boolean = false;
        if (name === 'Moderation Tool') {
            state = this.currentLink.includes('/moderation-tool') || this.currentLink.includes('/custom-menu') || this.currentLink.includes('restaurants');
        }
        if (name === 'Merchant Hygiene') {
            state = this.currentLink.includes('/merchant-hygiene/consumer') || this.currentLink.includes('/merchant-hygiene/direct');
        }
        return state;
    }
}