import { Injectable,Renderer2,ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormControl } from '@angular/forms';
@Injectable()
export class ModerationService {
    private url = environment;

    constructor(public http: HttpClient,
        private cdr: ChangeDetectorRef, 
        private renderer: Renderer2) {}

   /**
     * GET API call to fetch Questions
     * @param {number} page page number to get pagination
     * @returns Observable
     */
    getFeeds(page: number, filters: any) {
        const queryParams: string[] = [];

        if (filters) {
            const { status, last_updated, post_type, user_details, user_id, search_query, is_pinned, recently_updated } = filters;
    
            // Add filters to queryParams array if they exist
            if (status) queryParams.push(`status=${status}`);
            if (last_updated?.start_date) queryParams.push(`start_date=${last_updated.start_date}`);
            if (last_updated?.end_date) queryParams.push(`end_date=${last_updated.end_date}`);
            if (post_type) queryParams.push(`post_type=${post_type}`);
            
            // Handle user details together
            if (user_details) {
                const { id, user_name, display_name } = user_details;
                if (id) queryParams.push(`user_id=${id}`);
                if (user_name) queryParams.push(`user_name=${user_name}`);
                if (display_name) queryParams.push(`display_name=${display_name}`);
            }
    
            if (user_id) queryParams.push(`user_id=${user_id}`);
            if (search_query) queryParams.push(`search_query=${search_query}`);
            if (is_pinned) queryParams.push(`is_pinned=${is_pinned}`);
            if (recently_updated){ 
                queryParams.push(`sort_type=${recently_updated}`);
                queryParams.push(`sort_by=recently_updated`);
            }
        }
    
        // Join query parameters into a single string
        const filtersString = queryParams.join('&');
    
        // Make the HTTP GET request with the constructed query string
        return this.http.get(`${this.url.adminUrl}/feed/posts/feed?from_admin_panel=true&lat=19.1683972&lon=72.9441489&page_no=${page + 1}&${filtersString}`);
    }

    /**
     * GET API call to fetch specific Question
     * @param slug question unique identifier
     * @returns Observable
     */
    getFeed(slug: string,userId:number) {
        return this.http.get(this.url.adminUrl + '/feed/posts/' + slug + '?user_id='+userId+'&from_admin_panel=true');
    }

    /**
     * GET API call to fetch Comments
     * @param questionSlug question unique identifier
     * @param page page number to get pagination
     * @returns Observable
     */
    getComments(postSlug: string,userId:number, page: number) {
        return this.http.get(this.url.adminUrl + '/feed/posts/' + postSlug + '/comments?from_admin_panel=true&page_no=' + (page + 1)+'&user_id='+userId);
    }

    /**
     * GET API call to fetch specific Comment
     * @param id comment unique identifier
     * @param userId user id who created the comment
     * @returns Observable
     */
    getCommentsById(id: number, userId: any) {
        return this.http.get(this.url.adminUrl + '/feed/comments/' + id + '?from_admin_panel=true&user_id=' + userId);
    }

    /**
     * GET API call to fetch Recommendations
     * @param questionSlug question unique identifier
     * @param page page number to get pagination
     * @returns Observable
     */
    getRecommendations(postSlug: string,userId:number,page: number) {
        return this.http.get(this.url.adminUrl + '/feed/posts/' + postSlug + '/recommendations?from_admin_panel=true&page_no=' + (page + 1)+'&user_id='+userId);
    }

    /**
     * GET API call to fetch Tags
     * @param search search term to filter tags
     * @returns Observable
     */
    getTags(search: string) {
        return this.http.get(this.url.adminUrl + '/feed/posts/tags?from_admin_panel=true&search_query=' + search);
    }

    /**
     * POST API call to create Post
     * @param body body of the post to be created
     * @returns Observable
     */
    createPost(body) {
        if(body.post_type === 'list')
        delete body.selected_resto; 
        return this.http.post(this.url.adminUrl + '/feed/posts?from_admin_panel=true&', body);
    }

    /**
     * PUT API call to update Post
     * @param slug post unique identifier
     * @param body body of the post to be updated
     * @returns Observable
     */
    updatePost(slug: string, body) {
        if(body.post_type === 'list')
        delete body.selected_resto; 
        return this.http.put(this.url.adminUrl + '/feed/posts/' + slug + '?from_admin_panel=true', body);
    }

    /**
     * POST API call to create post
     * @param body body of the comment to be created
     * @returns Observable
     */
    createComment(body) {
        return this.http.post(this.url.adminUrl + '/feed/comments?from_admin_panel=true', body);
    }

    /**
     * PUT API call to update Comment
     * @param id comment unique identifier
     * @param body body of the comment to be updated
     * @returns Observable
     */
    updateComment(id: number, body) {
        return this.http.put(this.url.adminUrl + '/feed/comments/' + id + '?from_admin_panel=true', body);
    }

    /**
     * GET API call to search Restaurants
     * @param query keyword to filter restaurants
     * @returns Observable
     */
    searchRestaurant(query) {
        return this.http.get(this.url.adminUrl + '/feed/posts/restaurants?from_admin_panel=true&query=' + query);
    }

    /**
     * Get merchant on the basis of keyword search
     * @param searchQuery keyword search
     * @returns merchant list
     */
     getRestaurants(searchQuery:string){
        return this.http.get(this.url.consumerUrl+'/v1/feed/restaurants?page_no=1&query='+searchQuery); 
    }
    /**
     * help to find restaurant 
     * @param placeId place id which found from backend
     * @returns return  reaturant object
     */
    getRestaurantViaPlaceId(placeId:string){
        return this.http.get(this.url.consumerUrl+'/v1/feed/restaurants/places_restaurant_details?place_id='+placeId);  
    }

    /**
     * GET API call to fetch Bot Users
     * @returns Observable
     */
    getBotUsers() {
        return this.http.get(this.url.adminUrl + '/bot_users?from_admin_panel=true');
    }

    getItems(id:number,searchQuery?:string){
        let params = new HttpParams();
         params = params.append('restaurant_id', id);
         params = params.append('from_admin_panel',true);
         if(searchQuery)
         params = params.append('search_query', searchQuery);

        return this.http.get(this.url.adminUrl+'/feed/posts/items',{params});
    }

    getSignUrl(media:any){
        return this.http.post(this.url.adminUrl+'/feed/posts/media_pre_signed_urls',media);
    }

    getS3Url(url:string,file:File,header:any){
        return this.http.put(url,file,header);
    }
    getTypeOfTags(tagType:any,searchQuery:any){
        let params = new HttpParams();
        params = params.append('tag_type', tagType);
        params = params.append('from_admin_panel',true);
        if(searchQuery)
        params = params.append('search_query', searchQuery);
        return this.http.get(this.url.consumerUrl+'/v1/feed/internal_tags',{params});
    }
    updateCreatedDate(userId:any,body:any){
        return this.http.post(this.url.adminUrl+'/feed/posts/bulk_update_created_at_of_posts?from_admin_panel=true&user_id='+userId,body);
    }
    bulkUpdateStatus(body){
        return this.http.post(this.url.adminUrl+'/feed/posts/bulk_update_status_of_posts?from_admin_panel=true',body)
    }
    // All comment
    getAllComment(page: number, filters: any){
        const queryParams: string[] = [];

        if (filters) {
            const { post_id, status, last_updated, post_type, user_details, search_query, recently_updated } = filters;
    
            if (post_id) queryParams.push(`feed_post_id=${post_id}`);
            if (status) queryParams.push(`status=${status}`);
            if (last_updated?.start_date) queryParams.push(`start_date=${last_updated.start_date}`);
            if (last_updated?.end_date) queryParams.push(`end_date=${last_updated.end_date}`);
            if (post_type) queryParams.push(`post_type=${post_type}`);
            
            // Handling user details in one go
            if (user_details) {
                if (user_details.id) queryParams.push(`user_id=${user_details.id}`);
                if (user_details.user_name) queryParams.push(`user_name=${user_details.user_name}`);
                if (user_details.display_name) queryParams.push(`display_name=${user_details.display_name}`);
            }
    
            if (search_query) queryParams.push(`search_query=${search_query}`);
            if (recently_updated) queryParams.push(`sort_type=${recently_updated}`);
        }
    
        // Joining all query parameters
        const filtersString = queryParams.length ? queryParams.join('&') : '';
        
        return this.http.get(`${this.url.adminUrl}/feed/comments?from_admin_panel=true&page_no=${page + 1}&${filtersString}`);
    }

    public getMentionConfig(
        mentionDiv: HTMLElement,
        bodyControl:any,
        addTag:boolean = false,
        selectedMentions: any[] = [],
        restaurantAdHocTags: any = {},
        list:any = {}
        ) {
        return {
          triggerChar: '@',
          mentionSelect: (item: any) => {
            const placeholder = `Loading...`;
            if (item && item.place_id) {
              this.getRestaurantViaPlaceId(item.place_id).subscribe((place: any) => {
                const tagRestaurant = `<span class="tag-restaurant" id="${place.restaurant_id}" data-original-text="@${place.name}">@${place.name}</span> `;
                this.updateTextareaContent(mentionDiv,bodyControl,placeholder,tagRestaurant);
                if (addTag) {
                    selectedMentions.push(place);
                    restaurantAdHocTags.addControl(place.restaurant_id, new FormControl(''));
                    restaurantAdHocTags.get([place.restaurant_id]).valueChanges.subscribe((e) => {
                      this.getAllTypeOfTagsList('restaurant_ad_hoc', e, list, 'restaurant_ad_hoc');
                    });
                  }
              }, (error) => {
                this.updateTextareaContent(mentionDiv,bodyControl,placeholder, `<!Error: Could not load restaurant>`);
              });
            } else {
              const tagRestaurant = `<span class="tag-restaurant" id="${item.restaurant_id}" data-original-text="@${item.name}">@${item.name}</span> `;
              this.updateTextareaContent(mentionDiv,bodyControl,placeholder, tagRestaurant);
              if (addTag) {
                selectedMentions.push(item);
                restaurantAdHocTags.addControl(item.restaurant_id, new FormControl(''));
                restaurantAdHocTags.get([item.restaurant_id]).valueChanges.subscribe((e) => {
                  this.getAllTypeOfTagsList('restaurant_ad_hoc', e, list,'restaurant_ad_hoc');
                });
              }
            }
            return placeholder;
          },
          allowSpace: true,
          disableSort: true,
          disableSearch: true,
          labelKey: 'name'
        };
      }

      // Method to update the textarea content
    updateTextareaContent(mentionDiv: HTMLElement,bodyControl:any,placeholder: string, actualContent: string) {
        setTimeout(() => {
            // After ensuring placeholder is rendered, replace it with actual content
            const updatedValue = mentionDiv.innerHTML.replace(placeholder, actualContent);
            // Update both the form control and the contenteditable div
            bodyControl?.setValue(updatedValue);
            this.renderer.setProperty(mentionDiv, 'innerHTML', updatedValue);
            // Ensure the DOM is updated after setting the new content
            this.cdr.detectChanges();
            // Move the cursor to the end after updating the content
            this.moveCursorToEnd(mentionDiv);
        }, 100); 
    }

    // Function to move the cursor to the end of the contenteditable div
    public moveCursorToEnd(mentionDiv: HTMLElement) {
        const el = mentionDiv;
        // Ensure the contenteditable div is focused
        el.focus();
        if (document.createRange && window.getSelection) {
            const range = document.createRange();
            const selection = window.getSelection();
            // Move cursor to the end of the content
            range.selectNodeContents(el);
            range.collapse(false);  // Collapse range to end of content
            selection.removeAllRanges();  // Remove any existing selections
            selection.addRange(range);    // Add the new range
        }
    }

    public convertSpanTagsToPlaceholders(mentionDiv: HTMLElement):string {
        // Clone the mentionDiv so that the original content remains unchanged
        const clonedDiv = mentionDiv.cloneNode(true) as HTMLElement;
        // Find all span elements with class 'tag-restaurant' inside the contenteditable div
        const spans = clonedDiv.querySelectorAll('span.tag-restaurant');
        spans.forEach((span: HTMLSpanElement) => {
            const restaurantId = span.getAttribute('id'); // Get the restaurant ID
            const placeholder = `<!Restaurant-${restaurantId}>`; // Create the placeholder
            // Replace the span with the placeholder
            const textNode = document.createTextNode(placeholder);
            span.replaceWith(textNode);
        });
        return clonedDiv.innerText
    }


    editComment(mentionDiv: HTMLElement, bodyControl:any,selectedMentions=[],restaurantAdHocTags?:any) {
        // Get the innerHTML of the contenteditable div
        const currentContent = mentionDiv.innerHTML;
        // Remove spans that no longer have content (e.g., user erased them)
        this.modifiedSpans(mentionDiv,selectedMentions,restaurantAdHocTags);
        // Update the form control value with the current content of the div
        bodyControl?.setValue(currentContent);
    }

    // Method to clean up empty or broken span elements
    modifiedSpans(mentionDiv: HTMLElement,selectedMentions=[],restaurantAdHocTags?:any): void {
        // Find all span elements inside the contenteditable div
        const spans = mentionDiv.querySelectorAll('span[id]');
        spans.forEach((span: HTMLSpanElement) => {
            const originalText = span.getAttribute('data-original-text') || span.innerText;
            if (span.innerText !== originalText) {
                // If the content inside the span was modified, keep the text but remove the span
                const textNode = document.createTextNode(span.innerText);
                span.replaceWith(textNode);
                 for (let i = 0; i < selectedMentions?.length; i++) {
                    if (selectedMentions[i].restaurant_id === span.getAttribute('id')) {
                        restaurantAdHocTags.removeControl(selectedMentions[i].restaurant_id);
                        selectedMentions.splice(i, 1);
                    } 
                }
                // Force change detection to update the DOM
                this.cdr.detectChanges();
                // Move the cursor to the end after updating the content
                this.moveCursorToEnd(mentionDiv)
            }
        });
    }

    getAllTypeOfTagsList(tagType:string,searchQuery:string,list:any,tagList:string){
        this.getTypeOfTags(tagType,searchQuery).subscribe((tags:any) => {
            list[tagList] = tags;
        });
    }

}
