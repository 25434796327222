<div id="bulkUpload" class="thrive-container table-grid-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="['/merchant']">
                <mat-icon class="arrowBack">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Upload Certificate</h2>
        </div>
    </div>

    <div class="thrive-card mt-0" id="merchantUploadCretificate">
        <div class="thrive-toast_info mb-16">
            <span>Info:</span>
            Upload in .pdf format only
        </div>
        <div class="buttons-row">
            <form [formGroup]="uploadCertificate">

                <div class="flex-form">
                    <p>Select certificate type.<sup>*</sup></p>
                    <div>
                        <mat-radio-group color="primary" formControlName="certificate_type">
                          <mat-radio-button value="gst">GST</mat-radio-button>
                          <mat-radio-button value="fssai">FSSAI</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-error *ngIf="uploadCertificate.get('certificate_type').hasError('required') && uploadCertificate.get('certificate_type')!.dirty">
                        Please select GST or FSSAI.
                    </mat-error>
                </div>
                
                <input type="file" #fileInput hidden (change)="onFileSelected($event)" multiple accept=".pdf"/>
                <!-- Styled Button -->
                <button class="btn-thrive_secondary mt-20" (click)="fileInput.click()">Choose Files</button>

                <ul class="basetitle">
                    <li *ngFor="let file of selectedFiles">
                        {{ file.name }} ({{ file.size | number }} bytes)
                        <button mat-icon-button color="warn" [matTooltip]="'Delete'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="removeFile(file)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </li>
                </ul>

            </form>
        </div>
    </div>

    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary"
            mat-button (click)="onSubmit()" 
            [disabled]="uploadCertificate.invalid">
                <mat-icon>add</mat-icon>
                <span>Upload</span>
            </button>
        </div>
    </div>
    
</div>