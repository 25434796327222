<input #itemPdfUpload accept=".pdf" style="display: none" name="item_img_upload" type="file" (change)="onPdfUpload($event)" />
<div class="container-img w-100">
    <div (click)="itemPdfUpload.click()" class="property-container mt-30" *ngIf="!pdfUrl">
        <img src="assets/image-uploader.svg" alt="upload image" />
        <div class="subtitle mt-10">{{title}}</div>
        <button class="btn-thrive_primary mt-10">Browse</button>
        <div class="property-row mt-30">
            <div class="property-column">
                <span class="property-name">File Type</span>
                <span class="property-value subbase">.PDF</span>
            </div>
            <div class="property-column">
                <span class="property-name">Max size</span>
                <span class="property-value subbase">{{fileSize}}MB</span>
            </div>
        </div>
    </div>
    <div class="img--4-3 position-relative" *ngIf="pdfUrl">
        <div class="icon_button">
            <button (click)="itemPdfUpload.click()" mat-icon-button class="btn-thrive_icon">
                <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="delete" (click)="removePdf()" mat-icon-button class="btn-thrive_icon delete-btn">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </div>
        <iframe [src]="pdfUrl | safe: 'resourceUrl'" style="width: 100%;height: 100%" type="application/pdf"></iframe>
    </div>
</div>
