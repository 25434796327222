import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MerchantUsersService} from '../merchant-users.service';
import Swal from 'sweetalert2';
import {MatOptionModule} from '@angular/material/core';
import {MatSelect, MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {Ichain, Imerchant} from './chainsormerchants';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
import { debounceTime } from 'rxjs';
import { startWith } from 'rxjs';

@Component({
    selector: 'app-merchant-users-create',
    templateUrl: './merchant-users-create.component.html',
    styleUrls: ['./merchant-users-create.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatSelectModule,
        MatSlideToggleModule,
        NgxMatSelectSearchModule,
        AccessControlDirective
    ],
    providers: [MerchantUsersService]
})
export class MerchantUsersCreateComponent implements OnInit {
    merchantForm: FormGroup;
    editId: string;
    filterChains: Array<Ichain>;
    getMerchantList: Array<Imerchant>;
    getChainList: Array<Ichain>;
    selectedMerchant:Imerchant;
    role: number;
    roles: Array<object> = [
        {name: 'Merchant', id: 1},
        {name: 'Chain', id: 2},
        {name: 'Sub chain', id: 3},
        {name: 'Support', id: 5}
    ];
    searchMerchantControl: FormControl = new FormControl('');
    selectedSubchain:any = [];
    constructor(private merchantUserService: MerchantUsersService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {}
    ngOnInit() {
        this.merchantForm = this.fb.group<any>({
            fname: new FormControl('', [Validators.required]),
            lname: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]),
            mobile: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
            is_static_pin: new FormControl(false),
            merchant_id:new FormControl(),
            chain_id:new FormControl(),
            otp: new FormControl(''),
            role: new FormControl(1, Validators.required),
            merchants: new FormControl(''),
            subchains: new FormControl('')
        });
        this.editId = this.route.snapshot.params.id;
        this.initialise();
        this.getChains(()=>{
            this.filterChains = this.getChainList;
            this.getMerchants('',()=>{
                if(this.editId !== 'new'){
                    this.setFormValue();
                } 
            },true);
        });
    }

    /**
     * To initialise the search value and check the length of the value
     */
    initialise() {
        this.searchMerchantControl.valueChanges.pipe(startWith(''),debounceTime(500)).subscribe((value: string) => {
            if(value){
                value = value?.toLowerCase();
                this._filter(value);
            }
        });
    }

    /**
     * To filter the search value from Array of objects
     * @param value To compare and check if its included in array of objects
     * @returns Values that are included in the array
     */
    private _filter(value: string) {
        switch (this.merchantForm.get('role').value) {
            case 1:
                this.getMerchants(value,()=>{
                    if(this.selectedMerchant){
                        if((this.getMerchantList.findIndex(mb=>mb.id === this.selectedMerchant.id) < 0)){
                            this.getMerchantList.unshift(this.selectedMerchant);
                        } 
                    }
                },true)
            break;
            case 3:
                this.getMerchants(value,()=>{
                    let merchant:Imerchant[] =  this.getMerchantList;
                    let newList:Imerchant[] = [...this.selectedSubchain];
                    merchant.forEach(m=>{
                        if(this.selectedSubchain.findIndex(mb=>mb.id === m.id) < 0)
                        newList.push(m)
                    });
                    this.getMerchantList = newList; 
                },false);
            break;
            case 2:
                this.filterChains = [];
                this.filterChains = this.getChainList.filter((chain: any) => chain?.name?.toLowerCase().includes(value));
            break;
        }
        return;
    }

    onSelect(event:MatSelect,role:string){
        switch (role) {
            case 'merchant':
                let merchant:Imerchant = this.getMerchantList.find(m => m.id === event.value)
                this.merchantForm.get('merchants').setValue(merchant);
                this.selectedMerchant = merchant;
            break;
            case 'subchain':
                this.selectedSubchain = [] = event.value;
            break;
        } 
    }

    getMerchants(searchText:string,cb:any,merchant?:boolean){
        this.merchantUserService.getMerchantList(searchText,merchant).subscribe((response:any)=>{
            this.getMerchantList =  response.merchants;
            cb();
        }) 
    }

    getChains(cb:any){
        this.merchantUserService.getChainList().subscribe((response:any)=>{
            this.getChainList = response.chains;
            cb();
        })   
    }

    
    /**
     * To check if mobile number is already present or not
     * @param value Parameter to get value of mobile number
     */
    checkMobile(value: string) {
        if (this.merchantForm.get('mobile').value.length == 10) {
            this.merchantUserService.checkValidPhoneEmail(value).subscribe(
                () => {},
                () => {
                    this.merchantForm.get('mobile').setErrors({mobilePresent: true});
                }
            );
        }
    }

    /**
     * To check if email is already present or not
     * @param value Parameter to get value of email address
     */
    checkEmail(value: string) {
        const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/;

        if (pattern.test(this.merchantForm.get('email').value)) {
            //If role is selected as support then check form email if it includes '@hashtagloyalty.com'
            if (this.merchantForm.value.role === 5) {
                if (!this.merchantForm.value.email.includes('@hashtagloyalty.com')) {
                    this.merchantForm.get('email').setErrors({inValid: true});
                } else {
                    this.merchantForm.get('email').setErrors(null);
                }
            } else {
                this.merchantUserService.checkValidPhoneEmail(value).subscribe(
                    () => {
                        this.merchantForm.get('email').setErrors(null);
                    },
                    () => {
                        this.merchantForm.get('email').setErrors({emailPresent: true});
                    }
                );
            }
        }
    }

    /**
     * to create a form to edit data of a user or create a new user
     */
    setFormValue() {
        //in case of editing a merchant user
        this.merchantUserService.getMerchantUserById(this.editId).subscribe((merchantInfo: any) => {
           
            switch (merchantInfo.role) {
                case 'Merchant':
                    this.role = 1;
                    this.selectedMerchant = merchantInfo.merchants;
                    if(merchantInfo.merchants && (this.getMerchantList.findIndex(mb=>mb.id === merchantInfo.merchant_id) < 0)){
                        this.getMerchantList.unshift(merchantInfo.merchants);
                    }
                    break;
                case 'ChainOwner':
                    this.role = 2;
                    break;
                case 'Support':
                    this.role = 5;
                    break;
                default:
                    this.role = 3;
                    this.selectedSubchain = merchantInfo.subchains;
                    let merchant:Imerchant[] =  this.getMerchantList;
                    let newList:Imerchant[] = [...this.selectedSubchain];
                    merchant.forEach(m=>{
                        if(this.selectedSubchain.findIndex(mb=>mb.id === m.id) < 0)
                        newList.push(m)
                    });
                    this.getMerchantList = newList;
                    break;
            }
            this.merchantForm.patchValue({...merchantInfo, role: this.role});
        });
    }
    /**
     * To get filterOptions and selection of role value as 1,2,3 and 5
     */
    onRoleSelect() {
        if(this.merchantForm.get('role').value === 5){
            if (!this.merchantForm.get('email').value.includes('@hashtagloyalty.com')) {
                this.merchantForm.get('email').setErrors({inValid: true});
            } else {
                this.merchantForm.get('email').setErrors(null);
            }
        }
    }
    /**
     * to submit the data of the form and show pop up messages accordingly
     */
    submitForm() {
        if(this.merchantForm.valid){
            if (this.editId !== 'new') {
                this.merchantUserService.updateMerchantUser(this.editId, this.merchantForm.value).subscribe(() => {
                    //edit merchant
                    Swal.fire('Updated!', 'User has been updated!', 'success');
                    this.router.navigateByUrl('/merchant-users');
                });
            } else {
                this.merchantUserService.createMerchantUser(this.merchantForm.value).subscribe(() => {
                    //create merchant
                    Swal.fire('Created!', 'User has been successfully created!', 'success');
                    this.router.navigateByUrl('/merchant-users');
                });
            }
        } else {
            this.merchantForm.markAllAsTouched();
        }
    }
}
